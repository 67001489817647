@font-face {
  font-family: "OpenSansHebrew";
  src: url(./fonts/OpenSansHebrew-Bold.ttf) format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "OpenSansHebrew";
  src: url(./fonts/OpenSansHebrew-BoldItalic.ttf) format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "OpenSansHebrew";
  src: url(./fonts/OpenSansHebrew-ExtraBold.ttf) format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "OpenSansHebrew";
  src: url(./fonts/OpenSansHebrew-ExtraBoldItalic.ttf) format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "OpenSansHebrew";
  src: url(./fonts/OpenSansHebrew-Italic.ttf) format("truetype");
  font-style: italic;
}

@font-face {
  font-family: "OpenSansHebrew";
  src: url(./fonts/OpenSansHebrew-Light.ttf) format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "OpenSansHebrew";
  src: url(./fonts/OpenSansHebrew-LightItalic.ttf) format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "OpenSansHebrew";
  src: url(./fonts/OpenSansHebrew-Regular.ttf) format("truetype");
}

.fill-red path {
  fill: #bc1e46;
}

.fill-gray path {
  fill: #666f80;
}

.fill-white path {
  fill: #ffffff;
}

.stroke-gray path {
  stroke: #666f80;
}

body {
  box-sizing: border-box;
  font-family: "OpenSansHebrew";
  /* direction: rtl; */
  
}




input,
label {
  display: block;
}

input {
  border-radius: 100px;
  border: solid 1px #e3e3e3;
  height: 2.5em;
  width: 100%;

  font-family: OpenSansHebrew;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #666f80;
}

input:active,
input:focus {
  outline: 0;
}

input::placeholder,
input::-webkit-input-placeholder {
  font-family: OpenSansHebrew;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #929aa9;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
}

form {
  margin-block-end: 0;
}

button {
  background-color: #bc1e46;
  color: white;
  border-radius: 100px;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid #bc1e46;
  padding: 0.375rem 0.75rem;
  width: 35%;
  height: 3.6em;
  box-shadow: 0 10px 20px 0 rgba(255, 102, 51, 0.01);
  font-weight: bold;
}
button:active,
button:focus,
button:hover {
  cursor: pointer;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #bc1e4699;
}

.loading-animation-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tabs {
  display: flex;
  border-bottom: 1px solid #dedede;
}

.tab {
  display: block;
  width: 100%;
  height: 52px;
  line-height: 52px;
  text-align: center;
  color: #6e1427;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  border-bottom: 3px solid transparent;
}

.tab.active {
  color: #bc1e46;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  border-color: #bc1e46;
}

.checkbox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.checkbox label {
  margin-left: 10px;
  border: 1px solid #d3d6e4;
  border-radius: 4px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}
.checkbox label svg {
  opacity: 0;
}
.checkbox.active svg {
  opacity: 1;
}
.checkbox input {
  display: none;
}
.checkbox input:checked label + svg {
  opacity: 1;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #BC1E46 !important;
  opacity: 1 !important;
}

.MuiSwitch-colorSecondary.Mui-checked {
  color: #FFFFFF !important;
}

.MuiSwitch-root {
  width: 51px !important;
  height: 31px !important;
  padding: 0 !important;
  border-radius: 100px;
}

.MuiSwitch-thumb {
  width: 26px !important;
  height: 25px !important;
}

.MuiSwitch-switchBase {
  left: -6px !important;
  top: -6px !important;
}

.confirmation-window-image {
  margin-top: 30px;
  text-align: center;
  max-width: 100vw;
  max-height: 100vh;
}

.confirmation-window-image img {
    max-width: 85vw;
    max-height: 100vh;
}

.confirmation-window-tag {
  margin-top: 30px;
  text-align: center;
}

.confirmation-window-row {
  margin-top: 20px;
  margin-bottom: 25px;
  height: 30%;
  text-align: center;
  font-size: 18px;
  line-height: 1.11;
  color: #6e1427;
}

.confirmation-window-footer {
  display: flex;
  justify-content: space-between;
}

.confirmation-window-footer button {
  width: 130px;
  min-height: 47px;
  border-radius: 10px;
  border: solid 1px #bc1e46;
}

.confirmation-window-footer button.white {
  background-color: transparent;
  color: #bc1e46;
}
.confirmation-window-footer button.center {
  margin: auto;
}

.success-window {
  border-radius: 10px;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.25);
  border: solid 2px #40e25b;
  background-color: #ffffff;
  padding: 17px 7px 10px;

  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.error-window {
  border-radius: 10px;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.25);
  border: solid 2px #e24047;
  background-color: #ffffff;
  padding: 17px 7px 10px;

  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.success-snackbar .success-window {
  position: fixed;
  top: auto;
  bottom: 20px;
  left: 20px;
  z-index: 2000;
  text-align: center;
}

.error-snackbar .error-window {
  position: fixed;
  top: auto;
  bottom: 20px;
  left: 20px;
  z-index: 2000;
  text-align: center;
}

.error-field label {
  color: #e24047 !important;
}
.error-field input,
.error-field textarea {
  border-color: #e24047 !important;
}

.message-card {
  text-align: right;
  padding: 1rem;
  box-sizing: border-box;
  box-shadow: 0 4px 30px 0 rgb(110 20 39 / 12%);
  width: 100%;
}

.no-results-filler-reverse {
  text-align: left !important;
}
.no-results-filler {
  text-align: right;
}
.no-results-filler > img {
  margin-bottom: 10px;
}
.no-results-filler > div {
  text-align: right;
  color: #3e4954;
}

.hidden {
  display: none !important;
}

.justify-content-center {
  justify-content: center;
}

body a, body a:hover {
  text-decoration: none;
  color: #6e1427;
}

input[type="checkbox"] {
  border: 1px solid #d3d6e4;
  width: 16px;
  height: 16px;
  margin: auto 0;
  border-radius: 4px;
}

input[type="checkbox"]:checked {
  background-color: #ffffff;
}

.back-link > span {
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

@media screen and (min-width: 1024px) {
  .no-results-filler {
    margin-right: 50px;
  }
  .no-results-filler > img {
    margin-bottom: 10px;
  }
  .no-results-filler > div {
    font-size: 30px;
  }
}

.full-width {
  width: 100%!important;
}

/* ------------- Glassix widget css ----------------- */
#glassix-widget-iframe-container.open.animated {
  position: flex;
  bottom: 15% !important; /* Example: Change the position */
  max-height: 300px !important; /* Example: Adjust height */
  height: calc(100% - 151px); 
  max-height: 590px; 
  right: 24px; 
  left: auto;
  top:auto !important;
}

#glassix-container{
  height: 50%; 
  max-height: 400px; 
  right: 24px; 
  left: auto;
}